import type { QueryClient } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useSyncExternalStore,
} from "react";

import { getElementContent, getPageContent, hasSSLCertificate } from "@pm2/api";
import { parseElementXML, parsePageXML } from "@pm2/content-engine";
import { useIsServerSide } from "@pm2/react-utils";
import type { UrlPattern } from "@pm2/sitemap-utils";
import {
  extractPatternFromUrl,
  isPartOfMegaMenu,
  openMegaMenuAtBreadcrumb,
  recursivelyFindResourceById,
} from "@pm2/sitemap-utils";
import type { Store } from "@pm2/store";
import { useStore } from "@pm2/store";
import { nProgressStore } from "@pm2/ui-components";
import { usePm2Modal } from "@pm2/ui-lightbox";
import type {
  LinkProps,
  LocationData,
  NavigationManagerContextType,
} from "@provider/navigation";
import {
  NavigationManagerProvider,
  useLocation,
  useNavigationManager,
} from "@provider/navigation";
import type { UserIdentity } from "@provider/user-identity";
import { useUserIdentityState } from "@provider/user-identity";
import { breakpoints } from "@ui/css";
import { createCacheWrapper } from "@utils/cache";
import { isPlatformNodeJS, reduceNodeOffset } from "@utils/common";
import { HOURS_TO_MS } from "@utils/constants";
import { useMatchBreakpoint, useStateRef } from "@utils/react";

type Props = {
  basePath?: string;
  children: React.ReactNode | React.ReactNode[];
};

const BasePathContext = createContext<string>("");

type BrowserSyncContextValue = {
  flush: (href: string) => void;
  subscribe: (cb: () => void) => () => void;
};
const BrowserSyncContext = createContext<BrowserSyncContextValue>({
  flush: () => {
    throw new Error("BrowserSyncContext: no integration provided");
  },
  subscribe: () => {
    throw new Error("BrowserSyncContext: no integration provided");
  },
});

/**
 * @note - combined in-memory and browser history routing
 *
 * PM2 has some very specific requirements for the navigation provider, where
 * we need to ensure that browser history is honered and triggers updates in
 * content - but the previously rendered page is retained until all relevant
 * data has been fetched to actually render the next one
 *
 * in order to achieve this, we have made this custom implementation which is
 * essentially a combination between an in-memory navigation and a browser
 * history that can be flushed to the in-memory state
 *
 * @note - ignores unwanted searchParams
 *
 * in the past we experienced DoS attacks where attackers had figured out that
 * they could bypass our caching by applying random query strings to URLs and
 * thereby force the system to re-render the same pages infinitely
 */
export function PM2NavigationProvider(props: Props) {
  const [href, setHref] = useStateRef(window.location.href);
  const subscribersRef = useRef(new Set<() => void>());
  const browserSubscribersRef = useRef(new Set<() => void>());

  const browserSyncContext = useMemo(
    (): BrowserSyncContextValue => ({
      flush: (href) => setHref(href),
      subscribe: (cb) => {
        const uniqueCb = () => cb();

        browserSubscribersRef.current.add(uniqueCb);

        return () => browserSubscribersRef.current.delete(uniqueCb);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const managerContext = useMemo((): NavigationManagerContextType => {
    return {
      getPath: () => extractPm2PathFromHref(href.current, props.basePath),
      subscribe: (cb) => {
        const uniqueCb = () => cb();

        subscribersRef.current.add(uniqueCb);

        return () => subscribersRef.current.delete(uniqueCb);
      },

      push: (nextPath) => {
        const nextUrl = new URL(nextPath, window.location.origin);
        if (props.basePath && !nextUrl.pathname.startsWith(props.basePath)) {
          nextUrl.pathname = `${props.basePath}${nextUrl.pathname}`;
        }

        // There's no need to pollute our history if we're already at
        // the place we're pushing to
        if (nextUrl.href === href.current || nextUrl.href === `${href}/`) {
          return;
        }

        history.pushState(null, "", nextUrl.href.replace(nextUrl.origin, ""));

        // manually trigger subscribers when updating state (as pushState isn't
        // implemented as an event)
        browserSubscribersRef.current.forEach((cb) => cb());
      },
      replace: (nextPath) => {
        const nextUrl = new URL(nextPath, window.location.origin);

        if (props.basePath && !nextUrl.pathname.startsWith(props.basePath)) {
          nextUrl.pathname = `${props.basePath}${nextUrl.pathname}`;
        }

        history.replaceState(
          null,
          "",
          nextUrl.href.replace(nextUrl.origin, "")
        );

        // manually trigger subscribers when updating state (as
        // pushState isn't implemented as an event)
        browserSubscribersRef.current.forEach((cb) => cb());
      },
      back: () => {
        history.back();
      },

      enhanceLink: (props, ref) => <PM2Link ref={ref} {...props} />,
    };
  }, [href, props.basePath]);

  // subscribe to the popState event, so that we can handle built-in browser
  // back/forwards navigation
  useEffect(() => {
    const handlePopState = () => {
      browserSubscribersRef.current.forEach((cb) => cb());
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    subscribersRef.current.forEach((cb) => cb());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href.current]);

  return (
    <BasePathContext.Provider value={props.basePath ?? ""}>
      <BrowserSyncContext.Provider value={browserSyncContext}>
        <NavigationManagerProvider manager={managerContext}>
          {props.children}
        </NavigationManagerProvider>
      </BrowserSyncContext.Provider>
    </BasePathContext.Provider>
  );
}

const PM2Link = forwardRef<HTMLAnchorElement, LinkProps>(function PM2Link(
  { onClick, ...props },
  ref
) {
  const queryClient = useQueryClient();
  const user = useUserIdentityState((state) => state.user);
  const store = useStore();
  const pm2Modal = usePm2Modal();
  const location = useLocation();
  const navigation = useNavigationManager();
  const isMobile = useMatchBreakpoint(breakpoints.mobile.andDown());

  const urlPattern =
    props.href !== undefined ? extractPatternFromUrl(props.href) : undefined;
  const parsedHref = parseHref(store, location, urlPattern);
  const target = props.target;

  const basePath = useContext(BasePathContext);
  const href = resolveHref(basePath, parsedHref);

  const handleNavigate = useCallback(
    async (evt: React.MouseEvent<HTMLAnchorElement>) => {
      await onClick?.(evt);

      if (!href) {
        // if no url was provided, then we cannot handle the link - bail out
        // and revert to built-in browser logic for href-less links
        return;
      }

      if (evt.isDefaultPrevented()) {
        // if default handling was prevented, then avoid doing any further
        // processing, so as to allow integrations to disable navigation as
        // you would with native links
        return;
      }

      if (evt.metaKey || evt.ctrlKey) {
        // if user is holding down modifier keys, then bail out and let the
        // browser use built-in navigation concepts to open links in new tabs
        return;
      }

      if (target === "_blank") {
        // if target is set to a new window, then let the browser do it's
        // thing in order to enable default handling of the link
        return;
      }

      if (target === "_lightbox") {
        evt.preventDefault();

        const contentPromise = fetchLightboxContent(
          queryClient,
          store,
          location,
          user,
          href,
          urlPattern
        );

        const ast = contentPromise.then((content) => content.ast);
        const options = contentPromise.then((content) => ({
          speechFile: content.speechFile,
        }));

        pm2Modal.fromAST(ast, options);
        return;
      }

      const url = new URL(href, window.location.href);

      if (url.host !== window.location.host) {
        // if we're navigating cross-domain, then let the default anchor
        // handler do it's thing (so that we honor requests to open in new
        // window out of the box)
        return;
      }

      if (url.pathname.startsWith(store.baseUrl)) {
        // if the pathname has been pre-fixed with the baseUrl already, then
        // prevent duplicate baseUrl injection
        url.pathname = url.pathname.replace(store.baseUrl, "");
      }

      evt.preventDefault();

      // In case we're navigating to an anchor on the current page, then do so
      // without triggering an actual navigation!
      if (
        url.hash &&
        url.pathname === location.pathname &&
        url.searchParams.toString() === location.search.toString()
      ) {
        navigation.replace(`${url.pathname}${url.search}${url.hash}`);

        const hash = url.hash.replace("#", "");
        const element =
          document.querySelector<HTMLElement>(`[name="${hash}"]`) ??
          document.getElementById(hash);

        if (element) {
          window.scrollTo({
            top: reduceNodeOffset(element).top - 100,
            behavior: "smooth",
          });
        }

        return;
      }

      try {
        // if we get here, it means that navigation must begin on some level;
        // make sure that the progress bar is displayed
        nProgressStore.dispatch(nProgressStore.actions.enable());

        // in the background begin fetching the page descriptor, so that we
        // can determine if the page is part of a megamenu (and in that case
        // simply expand the mega menu)
        const pagePath = await resolvePagePath(store, location, urlPattern);
        const pageDescriptor =
          pagePath !== undefined
            ? await store.getPageDescriptorByPath(pagePath)
            : undefined;

        if (pageDescriptor && isPartOfMegaMenu(store, pageDescriptor)) {
          // ... otherwise open the requested page inside the mega menu, so
          // that the user can simply browse pages from there
          openMegaMenuAtBreadcrumb(store, pageDescriptor, {
            isMobile: !!isMobile,
          });
        } else {
          navigation.push(`${url.pathname}${url.search}${url.hash}`);
        }
      } finally {
        // trigger navigation end event, as we've finished handling the
        // desired action
        nProgressStore.dispatch(nProgressStore.actions.disable());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props,
      target,
      href,
      location.pathname,
      location.search,
      store,
      user,
      pm2Modal,
      navigation,
      queryClient,
      isMobile,
    ]
  );

  return (
    <a ref={ref} {...props} href={href} onClick={handleNavigate}>
      {props.children}
    </a>
  );
});

const cache = createCacheWrapper({
  evictAfterMs: 1 * HOURS_TO_MS,
});

/**
 * custom location hook that resolves the currently active path within the
 * browser, and allows flushing it to the navigation provider state
 */
export function useBrowserLocation(): LocationData & {
  flush: () => void;
} {
  const serverSide = useIsServerSide();
  const location = useLocation();
  const basePath = useContext(BasePathContext);
  const { flush, subscribe } = useContext(BrowserSyncContext);

  const getSnapshot = () => {
    if (serverSide) {
      return undefined;
    }

    const path = extractPm2PathFromHref(window.location.href, basePath);

    return cache(path, () => {
      const url = new URL(path, "http://localhost/");
      return {
        href: window.location.href,
        asPath: path,
        pathname: url.pathname,
        search: url.search,
        searchParams: url.searchParams,
        hash: url.hash,
      };
    }).value;
  };

  const locationData = useSyncExternalStore(
    subscribe,
    getSnapshot,
    getSnapshot
  );

  return useMemo(
    () =>
      locationData
        ? {
            ...locationData,

            flush: () => {
              flush(locationData.href);
            },
          }
        : {
            ...location,
            flush: () => {
              // flushing becomes a no-operation on server side environments
              // where browser location isn't available
            },
          },
    [flush, location, locationData]
  );
}

export function extractPm2PathFromHref(
  href: string,
  basePath: string | undefined
): string {
  const url = new URL(href, "http://localhost/");

  // strip away basePath from the given link
  if (basePath && url.pathname.startsWith(basePath)) {
    url.pathname = url.pathname.substring(basePath.length);
  }

  // strip away all un-wanted searchParams
  for (const searchParamName of url.searchParams.keys()) {
    switch (searchParamName) {
      case "q":
      case "resource":
      case "dashboard":
      case "utm_source":
      case "utm_medium":
      case "utm_campaign":
      case "query":
      case "view":
      case "item":
      case "group":
      case "filters":
        // allow whitelist only
        break;

      default:
        url.searchParams.delete(searchParamName);
    }
  }

  return `${url.pathname}${url.search}${url.hash}`;
}

function parseHref(
  store: Store,
  location: LocationData,
  urlPattern: UrlPattern | undefined
): string | undefined {
  if (urlPattern === undefined) {
    return undefined;
  }

  if ("elementId" in urlPattern) {
    return `/learningObject/${urlPattern.elementId}`;
  }

  if ("pageId" in urlPattern) {
    const pageDescriptor = store.findPageDescriptorById(urlPattern.pageId);
    return pageDescriptor ? pageDescriptor.path : `/page/${urlPattern.pageId}`;
  }

  if ("resourceId" in urlPattern) {
    return `${location.pathname}?resource=${urlPattern.resourceId}`;
  }

  return urlPattern.href;
}

function resolveHref(
  basePath: string,
  href: string | undefined
): string | undefined {
  if (href === undefined) {
    return undefined;
  }

  if (href.startsWith("/")) {
    return `${basePath}${href}`;
  } else {
    if (canParseUrl(href)) {
      return href;
    }

    try {
      const url = new URL(
        href,
        isPlatformNodeJS() ? "http://localhost" : window.location.href
      );
      const protocol = hasSSLCertificate(url.hostname) ? "https://" : "http://";

      return `${protocol}${href}`;
    } catch (err) {
      console.error(err);
      return href;
    }
  }
}

function canParseUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (ignored) {
    return false;
  }
}

async function resolvePagePath(
  store: Store,
  location: LocationData,
  urlPattern: UrlPattern | undefined
): Promise<string | undefined> {
  if (urlPattern === undefined) {
    return undefined;
  }

  if ("elementId" in urlPattern) {
    return undefined;
  }

  if ("pageId" in urlPattern) {
    return store.getPagePathById(urlPattern.pageId);
  }

  if ("resourceId" in urlPattern) {
    return `${location.pathname}?resource=${urlPattern.resourceId}`;
  }

  return new URL(urlPattern.href, window.location.href).pathname;
}

async function fetchLightboxContent(
  queryClient: QueryClient,
  store: Store,
  location: LocationData,
  user: UserIdentity | undefined | null,
  href: string,
  urlPattern: UrlPattern | undefined
) {
  // start by loading the AST of the content to be printed
  const ast = await (async () => {
    if (urlPattern) {
      if ("elementId" in urlPattern) {
        return parseElementXML(
          queryClient,
          await getElementContent(store.config.id, urlPattern.elementId, {
            previewMode: store.previewMode,
          }),
          user
        );
      }

      if ("pageId" in urlPattern) {
        return parsePageXML(
          queryClient,
          await getPageContent(store.config.id, urlPattern.pageId, {
            previewMode: store.previewMode,
          }),
          user
        );
      }

      if ("resourceId" in urlPattern) {
        const pageDescriptors = await store.getResourcesForPage(
          await store.getPageDescriptorByPath(location.pathname)
        );
        const descriptor = recursivelyFindResourceById(
          pageDescriptors,
          urlPattern.resourceId
        );

        if (descriptor) {
          const content =
            descriptor.kind === "ContentElement"
              ? await getElementContent(
                  store.config.id,
                  urlPattern.resourceId,
                  {
                    previewMode: store.previewMode,
                  }
                )
              : await getPageContent(store.config.id, urlPattern.resourceId, {
                  previewMode: store.previewMode,
                });

          if (content) {
            return parsePageXML(queryClient, content, user);
          }
        }
      }
    }

    // if we get here, try to resolve the page by the qualified href
    const pageHref =
      urlPattern && "href" in urlPattern ? urlPattern.href : href;
    const pageDescriptor = await store.getPageDescriptorByPath(pageHref);

    if (!pageDescriptor) {
      return [];
    }

    return parsePageXML(
      queryClient,
      await getPageContent(store.config.id, pageDescriptor.id, {
        previewMode: store.previewMode,
      }),
      user
    );
  })();

  // based on the AST, determine if a speechFile should be added to the modal
  const speechFile = (() => {
    if (ast.length === 1 && ast[0] && "pm2" in ast[0]) {
      return ast[0].pm2.speechFileUrl;
    }
  })();

  return {
    ast,
    speechFile,
  };
}
