import type { Store } from "@pm2/store";
import { configureUserIdentityManager } from "@provider/user-identity";
import { isPlatformNodeJS } from "@utils/common";

export function configurePm2UserIdentityManager(store: Store) {
  if (isPlatformNodeJS()) {
    return;
  }

  configureUserIdentityManager({
    requestLogin: () => {
      const returnUrl = encodeURIComponent(window.location.href);

      const loginUrl =
        `${process.env["LOGIN_CONNECTOR_API"]}/Navigator/Navigator` +
        `?clientWebSite=${store.config.settings.loginProvider}` +
        `&clientWsSuccessUrl=${returnUrl}` +
        `&clientWsFailureUrl=${returnUrl}` +
        `&forceLogin=true`;

      window.location.href = loginUrl;
    },
    logout: () => {
      const returnUrl = encodeURIComponent(window.location.href);

      const logoutUrl = `${process.env["LOGIN_CONNECTOR_API"]}/Navigator/Logout?clientWebSite=${store.config.settings.loginProvider}&logoutSuccessUrl=${returnUrl}`;
      window.location.href = logoutUrl;
    },
  });
}
