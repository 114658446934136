import type { Store } from "@pm2/store";
import { pm2Config } from "@provider/pm2-config";

export function configurePm2Config(store: Store) {
  pm2Config.dispatch(
    pm2Config.actions.updateConfig({
      loginDisabled: store.config.settings.loginDisabled,
      audience: store.config.theme.targetAudience,
      trialAccessUrl: store.config.settings.trialAccessUrl,
      componentPreviewImage: store.config.theme.componentPreviewImage,
    })
  );
}
