import "./styles/global";

import { TrackJS } from "trackjs";

import { applyAuthzInterceptor, applyPreviewInterceptor } from "@pm2/api";
import { Store } from "@pm2/store";
import { applyComponentFrameworkClassnamesMiddleware } from "@provider/component-framework";
import { userIdentityStore } from "@provider/user-identity";

import { authenticateAndAuthorizeUser } from "./core/authz";
import { configurePm2Config } from "./core/config.pm2-config";
import { configurePm2UserIdentityManager } from "./core/config.user-identity-manager";
import { extractPm2PathFromHref } from "./core/provider.navigation";

if (process.env["PM2__TRACKJS__ENABLED"]) {
  TrackJS.install({
    application: process.env["PM2__TRACKJS__APP_NAME"],
    token: process.env["PM2__TRACKJS__TOKEN"] ?? "",
  });
}

const store = new Store(window.gStore);

configurePm2UserIdentityManager(store);
configurePm2Config(store);
applyComponentFrameworkClassnamesMiddleware();

authenticateAndAuthorizeUser(window.gStore.config, window.gLoginConnectorToken)
  .then(({ authz, user }) => {
    // apply user data to global identity store
    userIdentityStore.dispatch(userIdentityStore.actions.setUser(user ?? null));

    // apply necessary interceptors for the Axios requests to run correctly
    applyAuthzInterceptor(authz);

    if (store.previewMode) {
      applyPreviewInterceptor();
    }

    store.initClientSide(
      extractPm2PathFromHref(window.location.href, store.baseUrl).split(
        "#"
      )[0] ?? "/"
    );
  })
  .catch((reason) => {
    console.error("unable to authenticate and authorize user:", reason);

    alert(
      "Der opstod en fejl da vi forsøgte at logge dig på. Prøv evt. at genindlæse siden."
    );
  });

if (process.env["PM2__DISABLE_SSR"] === "true") {
  import("./bootstrap.render")
    .then(({ renderApp }) => renderApp(store))
    .catch((err) => {
      alert("Der opstod en fejl ved indlæsning af portalen, vi beklager.");
      console.error(err);
    });
} else {
  import("./bootstrap.hydrate")
    .then(({ hydrateApp }) => hydrateApp(store))
    .catch((err) => {
      alert("Der opstod en fejl ved indlæsning af portalen, vi beklager.");
      console.error(err);
    });
}

if (window.location.search.includes("ui-inspection=true")) {
  import("./dev.inspector-tool");
}
